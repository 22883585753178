<!--
 * @Description:
 * @Author: heqi
 * @Date: 2022-07-12 17:25:25
 * @LastEditTime: 2022-09-26 15:55:32
 * @LastEditors: heqi
 * @FilePath: \mfzj\src\views\layout\work\okr\myokr\component\OkrAdd.vue
-->
<template>
  <div class="okr-template-wrapper">
    <OkrTemPlate :okrData="okrLocalList" :date="date" @delete="deleteOkr" :cycleId="Number(cycleId)"></OkrTemPlate>
  </div>
</template>

<script>
import { reactive, toRefs, computed, nextTick } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import OkrTemPlate from './OkrTemplate.vue'
import eventBus from '@/utils/eventBus.js'

export default {
  name: 'OkrAdd',
  components: {
    OkrTemPlate
  },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const state = reactive({
      addDate: computed(() => `${route.query.year}-${route.query.month}`), // 日期
      date: {
        year: computed(() => route.query.year),
        month: computed(() => route.query.month)
      },
      selDate: {},
      cycleId: computed(() => route.query.cycleId),
      okrLocalList: {
        name: '',
        key_results: [{ id: 1, content: '', weights: 100, disabled: false }, { id: 1, content: '', weights: 0, disabled: false }, { id: 1, content: '', weights: 0, disabled: true }],
        status: 0
      }
    })

    const deleteOkr = () => {
      router.go(-1)
    }

    nextTick(() => {
      eventBus.$emit('alignDialogInit')
    })

    return {
      ...toRefs(state),
      route,
      deleteOkr
    }
  }
}
</script>

<style lang="less" scoped>
</style>
